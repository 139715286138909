<template  lang="pug">
	v-container
		v-row( align="center"  justify="start" )

			//-
			//- v-col(cols="12" sm="4")
				v-card(height="230px" color="ui-600"  )
					v-card-text(align="center" class="white--text")

						v-icon(size="90" class="white--text") mdi-account-star
						v-spacer
						p(class="headline") Segmentaciones y <br> recomendaciones
			
			//- v-col(cols="12" sm="4" align="center" justify="center" )
				v-card(height="230px" color="blue-500" :to="{name:'home'}" )
					v-card-text(class="white--text" )
						v-icon(size="90" class="white--text") mdi-chart-bell-curve-cumulative
						v-spacer
						p(class="headline") Analítica <br>  Digital

		
			//-v-col(cols="12" sm="4" v-if="checkRoleaAuthorization([theGroups.GUEST])" )
				v-card(height="230px" color="blue-600" :to="{name:'pim'}" )
					v-card-text(align="center" class="white--text")
						v-icon(size="90" class="white--text") mdi-file-document-box-search-outline
						v-spacer
						p(class="headline") PIM <br>

			//- v-row( align="center"  justify="center" )
			//- v-col(cols="12" sm="4" )
				v-card(height="230px" color="blue-500" :to="{name:'existencia'}" )
					v-card-text(align="center" class="white--text" )
						v-icon(size="90" class="white--text") mdi-garage-alert-variant
						v-spacer
						p(class="headline") Alerta <br> existencia <span class="caption"></span>
			//- v-col(cols="12" sm="4"  )
				v-card(height="230px" color="blue-600" :to="{name:'changelog'}" )
					v-card-text(align="center" class="white--text" )
						v-icon(size="90" class="white--text") mdi-format-list-bulleted
						v-spacer
						p(class="headline") Versiones <br>  <span class="caption"></span>
			//- v-col(cols="12" sm="4")
				v-card(height="230px" color="blue-500"  :to="{name:'replenishment'}" )
					v-card-text(align="center" class="white--text" )
						v-icon(size="90" class="white--text") mdi-paw
						v-spacer
						p(class="headline") Mascotas <br>  <span class="caption"></span>
			//- v-row( align="start"  justify="start" )
			//- v-col(cols="12" sm="4")
				v-card(height="230px" color="blue-500"  :to="{name:'ranking'}" )
					v-card-text(align="center" class="white--text" )
						v-icon(size="90" class="white--text") mdi-chevron-triple-up
						v-spacer
						p(class="headline") Ranking de resultados <br>  <span class="caption"></span>
			
			//- -v-col(cols="12" sm="4"  )
				v-card(height="230px" color="blue-600" :to="{name:'logs'}" )
					v-card-text(align="center" class="white--text" )
						v-icon(size="90" class="white--text") mdi-format-list-checks
						v-spacer
						p(class="headline") Pipeline Logs <br>  <span class="caption"></span>

		//- dinamicos
		v-row( align="start"  justify="start" )
			v-col(cols="12" sm="4" v-for="item, index in menuItemsByPermissions" :key="item.id" )
				v-card(height="230px" :color="cardColor(index)" @click="goToRoute(item.routeName)" )
					v-card-text(align="center" class="white--text" )
						v-icon(size="90" class="white--text") {{ item.icon }} 
						v-spacer
						p(class="headline" v-html="item.text")

			
</template>
<script>

	import { mapState } from 'vuex';
	import LogsService from '../../services/LogsService'
	import DataLayersMixin from '@/mixins/DataLayersMixin'
	const logsService = new LogsService();

	export default {
		name: 'DashboardPanel',
		data() {
			return {
				menuItems : [
					// {
					// 	id: 1,
					// 	text: 'Interfaz CAT',
					// 	routeName: 'interfaz_cat',
					// 	icon: 'mdi-phone-classic',
					// 	permissions: [ 'view_interfaz_cat' ]
					// },
					{
						id: 1.1,
						text: 'Recomendador CAT',
						routeName: 'recomendador_cat',
						icon: 'mdi-headset',
						permissions: [ 'view_interfaz_cat_2' ]
					},
					{
						id: 1.2,
						text: 'Buzon de mensajes',
						routeName: 'buzon_mensajes',
						icon: 'mdi-message-text',
						permissions: [ 'view_buzon_de_mesajes' ]
					},
					{
						id: 2,
						text: 'Segmentaciones y <br> recomendaciones',
						routeName: 'segmentador',
						icon: 'mdi-account-star',
						permissions: [ 'view_segmentaciones' ]
					},
					{
						id: 3,
						text: 'Segmentaciones dinámicas para caso de usos',
						routeName: 'casosdeuso',
						icon: 'mdi-archive',
						permissions: [ 'view_form_casosdeuso' ]
					},
					{
						id: 4,
						text: 'Recomendador Universal',
						routeName: 'ruform',
						icon: 'mdi-archive',
						permissions: [ 'view_form_ru' ]
					},
					{
						id: 5,
						text: 'Analítica <br>  Digital',
						routeName: 'home',
						icon: 'mdi-chart-bell-curve-cumulative',
						permissions: [ 'view_analitica_digital' ]
					},
					{
						id: 6,
						text: 'PIM',
						routeName: 'pim',
						icon: 'mdi-file-document-box-search-outline',
						permissions: [ 'view_pim' ]
					},
					{
						id: 7,
						text: 'Alerta <br> existencia <span class="caption"></span>',
						routeName: 'existencia',
						icon: 'mdi-garage-alert-variant',
						permissions: [ 'view_alerta_existencia' ]
					},
					{
						id: 8,
						text: 'Score PDP',
						routeName: 'score_pdp',
						icon: 'mdi-chart-donut',
						permissions: [ 'view_scorespdp' ]
					},
					{
						id: 9,
						text: ' Porcentaje Completitud',
						routeName: 'porcentaje_completitud',
						icon: 'mdi-file-percent-outline',
						permissions: [ 'view_porcentaje_completitud' ]
					},
					{
						id: 10,
						text: 'Versiones <br>  <span class="caption"></span>',
						routeName: 'changelog',
						icon: 'mdi-format-list-bulleted',
						permissions: [ 'view_versiones' ]
					},
					{
						id: 11,
						text: 'Mascotas <br>  <span class="caption"></span>',
						routeName: 'replenishment',
						icon: 'mdi-paw',
						permissions: [ 'view_mascotas' ]
					},
					{
						id: 12,
						text: 'Ranking de resultados <br>  <span class="caption"></span>',
						routeName: 'ranking',
						icon: 'mdi-chevron-triple-up',
						permissions: [ 'view_ranking_resultados' ]
					},
					{
						id: 13,
						text: 'Pipeline Logs <br>  <span class="caption"></span>',
						routeName: 'logs',
						icon: 'mdi-format-list-checks',
						permissions: [ 'view_pipeline_logs' ]
					},
					{
						id: 14,
						text: 'Logs de los formularios',
						routeName: 'form_logs',
						icon: 'mdi-table',
						permissions: [ 'view_forms_logs' ]
					},
					{
						id: 15,
						text: 'Caracola mágica',
						routeName: 'caracola',
						icon: 'mdi-dice-3',
						permissions: [ 'view_caracola' ]
					},
					{
						id: 17,
						text: 'Motor <br> de audiencias',
						routeName: 'motor_audiencias',
						icon: 'mdi-account-group',
						permissions: [ 'view_motor_audiencias' ]
					}
				]
			}
		},
		mixins: [DataLayersMixin],
		computed: {
			...mapState({
				userPermissions: state => state.session.permissions,
			}),
			menuItemsByPermissions() {
				return this.menuItems.filter( (item) => {
					return !item.hasOwnProperty('permissions') ? true : item.permissions.some( record => this.userPermissions.includes( record ) )
				})
			},
		},
		mounted() {
			// gtm datalayer
			this.dataLayerLoadedPagePanel()
		},
		methods: {
			// bg color item card menu
			cardColor(index) {
				return index % 2 == 0 ? 'blue-600' : 'blue-500';
			},
			onCompleteRoute(route){
				// registro en bitacora solo en modo production
				// if (process.env.NODE_ENV == 'production') {
				// 	logsService.postUserBrowserActivityLogs({url_path: route.path})
				// 	.then(response => {
				// 	})
				// 	.catch(error => console.log("error:", error.request.response));
				// } else {
	            //     console.log('navegación en modo development');
				// }
			},
			sendDatalayer(routeName) {
				switch(routeName) {
					case 'interfaz_cat':
					case 'recomendador_cat':
						this.dataLayerClickBtnRecomendadorCAT()
						break;
					case 'buzon_mensajes':
						this.dataLayerClickBtnBuzonMensajes()
						break;
					default:
				}
			},
			goToRoute(routeName) {
				// datalayers
				this.sendDatalayer(routeName);
				//
				this.$router.push({name: routeName}, this.onCompleteRoute);
			}
		}
	}
</script>



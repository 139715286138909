import BackendAuthService from "./BackendAuthService";

export default class LogsService extends BackendAuthService {

	constructor() {
		super();
	}
	// =============================================== tab1

	fetchLogs() {
		return this.httpCall('logs/all', {
			method: 'GET'
		});
	}

	formSegmenter() {
		return this.httpCall('logs/form-logs?form-output=csv&form=segmentador', {
			method: 'GET'
		})
	}

	formCasosDeUso() {
		return this.httpCall('logs/form-logs?form-output=csv&form=casos-de-uso', {
			method: 'GET'
		})
	}

	formAudiencesLogs() {
		return this.httpCall('logs/form-audiences-logs', {
			method: 'GET'
		})
	}

	// path inhabilitado
	postUserBrowserActivityLogs(data) {
		return this.httpCall('logs/browser-activity-log', {
			method: 'POST',
			data
		})
	}
}
